import React from "react"

const Affiliate = () => {
  const affiliateLinks = [
    {
      id: 1,
      name: "React - The Complete Guide (incl Hooks, React Router, Redux)",
      url:
        "https://click.linksynergy.com/deeplink?id=o1JCNdqL0gw&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Freact-the-complete-guide-incl-redux%2F",
    },
    {
      id: 2,
      name: "JavaScript - The Complete Guide 2020 (Beginner + Advanced)",
      url:
        "https://click.linksynergy.com/deeplink?id=o1JCNdqL0gw&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fjavascript-the-complete-guide-2020-beginner-advanced%2F",
    },
    {
      id: 3,
      name: "Understanding TypeScript - 2020 Edition",
      url:
        "https://click.linksynergy.com/deeplink?id=o1JCNdqL0gw&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Funderstanding-typescript%2F",
    },
    {
      id: 4,
      name: "NodeJS - The Complete Guide (incl. Deno, REST APIs, GraphQL)",
      url:
        "https://click.linksynergy.com/deeplink?id=o1JCNdqL0gw&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fnodejs-the-complete-guide%2F",
    },
    {
      id: 5,
      name: "Angular - The Complete Guide (2020 Edition)",
      url:
        "https://click.linksynergy.com/deeplink?id=o1JCNdqL0gw&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fthe-complete-guide-to-angular-2%2F",
    },
    {
      id: 6,
      name: "CSS - The Complete Guide 2020 (incl. Flexbox, Grid & Sass)",
      url:
        "https://click.linksynergy.com/deeplink?id=o1JCNdqL0gw&mid=39197&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fcss-the-complete-guide-incl-flexbox-grid-sass%2F",
    },
  ]

  return (
    <div className="my-1 category">
      <h1 className="text-medium text-center">Affiliate Courses Links</h1>
      <ul style={{listStyle:'none', padding:'0.3rem'}}>
        {affiliateLinks.map(link => (
            <li key={link.id}>
            <a
            className="text-xsmall text-dark"
            target="_blank"
            rel="noopener noreferrer"
            href={link.url}
            >&#x0003E; {link.name}</a>
            </li>
            ))}
            </ul>
      </div>
  )
}

export default Affiliate
