import React from "react"
import { Link } from "gatsby"
import HeadPost from "./headPost"
import PostImage from "./image"
import Tags from "./tags"

const Post = ({ title, date, tags, body, slug, read, image }) => {
  return (
    <Link to={slug} className="container post mb-1 colored">
    <div>
    <PostImage image={image} styles="cover-post" />
        <div className="container-body">
          <h1 className="text-dark text-medium">{title}</h1>
          <HeadPost date={date} readText="read" read={read} />
          <p className="text-xsmall">{body}</p>
        </div>
        </div>
      <div className="flex post p-1">
        <Tags tags={tags} />
      </div>
    </Link>
  )
}

export default Post
