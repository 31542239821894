import React from "react"
import Donate from "../Contact/donate"
import FollowMe from '../Contact/follow-me'
import Category from "./category"
import Affiliate from "../Affiliate/Affiliate"
import TopPosts from './topPosts'

const SideBar = ({ categories, social_links, topPosts }) => (
    <div className="SideBar">
      <div className="SideBar">
        <Category categories={categories} />
        <Affiliate />
        <TopPosts topPosts={topPosts} />
        <div className="mt-1">
        <Donate />
        <FollowMe
        social_links={social_links[0]}
        buttonColor="btn-twitter"
      />
      </div>
      </div>
    </div>
  )

export default SideBar
