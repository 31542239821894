import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import SEO from "../components/seo"
import Layout from "../layout/en"
import Post from "../components/Blog/Post/post"
import Header from "../components/NavBar/header"
import SideBar from "../components/Blog/Post/sidebar"

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { lang: { eq: "en" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }

        edges {
          node {
            fields {
              slug
            }
            id
            frontmatter {
              title
              date(formatString: "MMM DD, Y")
              tags
              image {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt(pruneLength: 100)
            timeToRead
          }
        }
      }
      site {
          siteMetadata {
            title
            social_links {
              name
              link
            }
          }
        }
    }
  `)

  const {
    id,
  } = data.allMarkdownRemark.edges[0].node
  return (
    <>
      <Header post={data.allMarkdownRemark.edges[0].node} />
      <Layout location={location}>
        <SEO title={data.site.siteMetadata.title} />
        <div className="home-container">
          <section className="post-container">
            {data.allMarkdownRemark.edges
              .filter(({ node }) => id !== node.id)
              .map(({ node }) => (
                <Post
                  title={node.frontmatter.title}
                  date={node.frontmatter.date}
                  read={node.timeToRead}
                  tags={node.frontmatter.tags}
                  body={node.excerpt}
                  image={node.frontmatter.image}
                  slug={node.fields.slug}
                  key={node.id}
                  readText="read"
                />
              ))}
          </section>
          <SideBar
            social_links={data.site.siteMetadata.social_links}
            categories={data.allMarkdownRemark.group}
            topPosts={data.allMarkdownRemark.edges}
          />
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
