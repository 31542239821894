import React from "react"
import Img from "gatsby-image"
import NoImage from "../../../assets/images/no-image.png"

const PostImage = ({ image, styles }) => (
    image ? (
          <Img
            className={`cover ${styles}`}
            alt="cover"
            fluid={image.childImageSharp.fluid}
          />
        ) : (
          <img src={NoImage} className={`cover ${styles}`} alt="cover" />
        )
)

export default PostImage
