import React from "react"
import { Link } from "gatsby"

const Category = ({ categories }) => (
  <div className="mb-1 category">
    <h1 className="text-medium text-center">Top Categories</h1>
    <div className="category-links">
      {categories
        .sort((a, b) => {
          if (a.totalCount > b.totalCount) {
            return - 1
          }
          if (a.totalCount < b.totalCount) {
            return 1
          }
          return 0
        })
        .map(tag =>
          tag.totalCount > 2 ? (
            <Link
              className={`badge badge-special badge-${tag.fieldValue}`}
              key={tag.fieldValue}
              to={`/tags/${tag.fieldValue}`}
            >
              {tag.fieldValue}
              {`(${tag.totalCount})`}
            </Link>
          ) : null
        )}
    </div>
  </div>
)

export default Category