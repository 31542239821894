import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import HeadPost from '../Blog/Post/headPost'
import NoImage from "../../assets/images/no-image.png"

const Header = ({post}) => {     
    const { frontmatter:{ title, image, date }, excerpt, timeToRead,fields:{slug} } = post
    return (
    <header className="header">
    <div className="header-cover-container header-full">
        <div className="header--container">
            <h1 className="text-xlarge text-weight-bold">{title}</h1>
            <HeadPost date={date} published=" Published " readText="read" read={timeToRead} />
            <p className="text-xsmall">{excerpt}</p>
            <Link className="btn btn-post" to={slug}>Read more &#8594;</Link>
        </div>
        {
            image?
            <Img
        className="cover-header"
        alt="cover-header"
        fluid={image.childImageSharp.fluid}
        />: <img src={NoImage} className="cover-header" alt="cover" />
    }
      </div>
    </header>
)
}

export default Header