import React from "react"
import { Link } from "gatsby"

const TopPosts = ({ topPosts }) => {
  //   const posts = [{}]
  const tp1 = "Advanced TypeScript Types cheat sheet (with examples)"
  const tp2 = "How to build a PWA from scratch with HTML, CSS and JavaScript ?"
  const tp3 = "15 must-know JavaScript array methods in 2020"
  const tp4 = "Redux VS React Context: Which one should you choose?"
  const tp5 =
    "How to build a GraphQl API from scratch with Node JS, Express, and MongoDB"

  return (
    <div className="category">
      <h1 className="text-medium text-center">Popular Posts</h1>
      {topPosts
        .filter(
          ({
            node: {
              frontmatter: { title },
            },
          }) =>
            title === tp1 ||
            title === tp2 ||
            title === tp3 ||
            title === tp4 ||
            title === tp5
        )
        .map(({ node }) => (
          <div className="category" key={node.id}>
            <Link to={node.fields.slug} className="text-xsmall text-underline text-dark">&#x0003E; {node.frontmatter.title}</Link>
          </div>
        ))}
    </div>
  )
}

export default TopPosts
