import React from "react"

const followMe = ({ social_links: { link, icon }, buttonColor }) => (
  <a
    target="_blank"
    className={`btn ${buttonColor}`}
    rel="noopener noreferrer"
    href={link}
  >
    <i className={icon}></i> Follow me
  </a>
)

export default followMe
